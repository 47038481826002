// @flow
import React, {Fragment} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import styles from './Category.module.scss';
import moment from 'moment';
import useCategoriesList from '../../hooks/use-categories-list';
import { Link } from 'gatsby';
import CategorySponsor from './CategorySponsor';

const _ = require('lodash');

const Category = ({data, category, meta}) => {
  const categories = useCategoriesList();

  return (
    <Fragment>
      <Header/>
      <section className='hero'>
        <div className={ `hero-body ${styles['category__hero']}` }>
          <div className={`container`}>
            <h1 className={ `title has-text-centered ${styles['category__hero-title']}` }>
              <span className={ `${styles['category__name']}` }>{meta.name}</span> articles
            </h1>
            <h2 className={ `subtitle has-text-centered ${styles['category__hero-subtitle']}` }>
              {meta.description}
            </h2>
          </div>
        </div>
      </section>
      <br/>
      <div className={`columns ${styles['category__wrapper']}`}>
        <div className='column is-one-quarter is-hidden-mobile'>
          <div className={`subtitle is-5 ${styles['category__label']}`}>
            Categories
          </div>
          <div className={`${styles['category__list']}`} >
            {categories.map((d) => (
              <Link to={`/${_.kebabCase(d.fieldValue.toLowerCase())}/`}
                className={`card ${styles['category__list-option']} ${styles['category__inactive']}`}
                activeClassName={`${styles['category__active']}`}>
                  <span className={`${styles['category__list-option-name']}`}>
                    {d.fieldValue}
                  </span>
                  <span className={`${styles['category__list-option-count']}`}>
                    {d.totalCount}
                  </span>
              </Link>
            ))}
          </div>
          <div className={`is-hidden-mobile ${styles['category__back']}`}>
            <Link to='/'>
              See all French tech blogs
            </Link>
          </div>
        </div>
        <div className={`column ${styles['category__articles']}`}>
        <span className={`${styles['category__articles-label']}`}>Latest articles</span>
        {data.articles.edges.map((d, index) =>(
          // This should be a component that takes blogs and article. And maybe blogs should be a dictionary for faster access, no need to iterate
          <div>
            <div className={`card ${styles['category__card']}`}>
              {data.blogs.edges.filter(edge =>
                edge.node.blogUrl === d.node.blogUrl
              ).map(edge => (
                <div className={`card-content ${styles['category__card-content']}`}>
                  <div className={`media ${styles['category__card-media']}`}>
                    <div className='media-left'>
                      <figure className={`image ${styles['category__logo']}`}>
                        <img src={`/media/${edge.node.companyName.toLowerCase().replace(/ /g,"").replace(/\//g,"")}.jpg`}/>
                      </figure>
                    </div>
                    <div className={`media-content`} >
                      <div>
                        <a className={`${styles['category__company-name']}`} target='_blank' rel='noopener noreferrer' href={edge.node.blogUrl}>
                          {edge.node.companyName}
                        </a>
                        <span className={`${styles['category__article-date']}`}> - {moment(d.node.postPublishedDate, 'YYYY/MM/DD').format('D MMMM YYYY')}</span>
                        <span className={`${styles['category__article-star']}`}>{d.node.favourite ? '⭐' : null}</span>			
                      </div>
                      <a className={`title ${styles['category__article-name']}`} href={d.node.postUrl} target='_blank' rel='noopener noreferrer'>
                        {d.node.postTitle}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {
              meta.sponsorMessage !== '-' & index % 10 === 2 ? <CategorySponsor data={meta}/> : null
            }
          </div>
        ))}
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}

export default Category;
