// @flow
import React from 'react';
import Category from '../components/Category';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { graphql } from 'gatsby';

const CategoryTemplate = ({data, pageContext}) => {
	const { category } = pageContext;
	const meta = data.categories.edges.filter((edge) =>
			edge.node.name === pageContext.category)[0].node;

	return (
		<Layout customSEO>
			<SEO
				title={`Best ${category} articles from French tech blogs`}
				description={`${meta.description}`}
				image={`${meta.image}`}/>
			<Category data={data} category={category} meta={meta}/>
		</Layout>
	)
};

export const query = graphql`
  query CategoryPage($category: String) {
		blogs: allGoogleSpreadsheetBlogsBlogs(filter: {
			online: {eq: "1"}
		}) {
      edges {
        node {
          companyUrl
					companyName
					companyDescription
					blogUrl
        }
      }
		}
		articles: allGoogleSpreadsheetBlogsArticles(
			filter: {
				online: {eq: "1"},
        category: {eq: $category}
      },
			sort: {
				fields: [postPublishedDate]
				order: DESC
			}
		) {
      edges {
        node {
					blogUrl
					postUrl
					postTitle
					category
					postPublishedDate
					favourite
        }
      }
		}
		categories: allGoogleSpreadsheetBlogsCategories {
			edges {
				node {
					name
					description
					image
					sponsorWebsite
					sponsorName
					sponsorCta
					sponsorMessage
					sponsorUrl
				}
			}
		}
  }
`;

export default CategoryTemplate;
