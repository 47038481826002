import React from 'react';

import styles from './CategorySponsor.module.scss';


const CategorySponsor = ({data}) => {
    return (
        <div className={ `${styles['sponsor']}` }>
            <p className={ `${styles['sponsor__label']}` }>
                SPONSORED BY <a href={data.sponsorWebsite}>{data.sponsorName}</a>
            </p>
            <p className={ `${styles['sponsor__message']}` }>{data.sponsorMessage}</p>
            <a className={ `${styles['sponsor__link']}` } href={data.sponsorUrl}>{data.sponsorCta}</a>
        </div>
    )
}

export default CategorySponsor;